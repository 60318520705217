function preveri_polnoletnost(){
	var d = new Date();
	var dan = $('dan').options[$('dan').selectedIndex].value;
	var mesec = $('mesec').options[$('mesec').selectedIndex].value;
	var leto = $('leto').options[$('leto').selectedIndex].value;
	
	var rojstvo = mktime(0,0,0,mesec,dan,leto);
	var pred_18_leti = mktime(0,0,0,d.getMonth(),d.getDate(),d.getFullYear()-18);
	
	if(pred_18_leti >= rojstvo){
		$('polnoleten').style.display = "none";
	}
	else {
		$('polnoleten').style.display = "";
	}
}

/* FUNKCIJE ZA POMOČ PRI UREJANJU ITEMOV */
/*---------------------------------------------------------------------- */
function izbrisi_item(id, vprasanje, ajax, dodatno){
	if(confirm(vprasanje)){
		// gre za izbris preko ajaxa
		if(ajax){
			var myRequest = new Request({method: 'post', url: $('root').value+dodatno['url'], onSuccess: function(responseText){
				$(dodatno['id_dispose']).dispose();
				}}).send(dodatno['poslji']);
		}
		// gre za izbris preko preusmeritve
		else {
			document.location = $('root').value + dodatno['lokacija'];
		}

	}
}

function izbrisi(vprasanje, lokacija){
	if(confirm(vprasanje)){
		document.location = $('root').value + lokacija;
	}
}

function ajax_izbrisi(id, poslji){
		var myRequest = new Request({method: 'post', url: $('root').value+'ajax.php', onSuccess: function(responseText){
			$(id).dispose();
			}}).send(poslji);
}

function shrani(id, poslji, osvezi, url){
		var myRequest = new Request({method: 'post', url: $('root').value+url, onSuccess: function(responseText){
			if(osvezi == true){
				window.location.reload();
			}
			else {
				if(id){
					$(id+'_item').innerHTML = responseText;
				}
				/*else {
					alert(responseText);
				}*/
				//$(id+'_item').innerHTML = responseText;
			}
			},
			onFailure: function(xhr){
				window.alert(xhr.responseText);
			}
		}).send(poslji);
}

function generate_post(id, tip){
	if($(id+'_uredi_'+tip)){
		return '&'+tip+'='+$(id+'_uredi_'+tip).value;
	}
	else {
		return '';	
	}
}

function generateSimplePost(tip){
	if($(tip)){
		return '&'+tip+'='+$(tip).value;
	}
	else {
		return '';	
	}
}

function dobi_select(id_polja, poslji, value){
	for(i = 0; i < $(id_polja).length; i++){
		polje = $(id_polja)[i];
		if(polje.selected == true){
			vrednost = 1;	
		}
		else {
			vrednost = 0;	
		}
		poslji = poslji+"&"+polje.value+"="+vrednost;
	}
	return poslji;
}

function dobi_select_value(id_polja, poslji){
	for(i = 0; i < $(id_polja).length; i++){
		polje = $(id_polja)[i];
		if(polje.selected == true){	
			poslji = poslji+"&"+id_polja+"="+polje.value;
		}
	}
	return poslji;
}

function popup(url, naslov, ne_odstrani, brez_zapri, sirina){
	if(!sirina){
		sirina = 600;
	}
	// odstranimo morebiten ostanek
	// if(!ne_odstrani){
	// 	$$('table[class="lightface"]').destroy();
	// }
	$$('table[class="lightface"]').destroy();
	
	// generiramo nov box
	modal = new LightFace.Request({
		url: $('root').value+url,
		title: naslov,
		width: sirina,
		draggable: true
	});
	
	if(brez_zapri != "true"){
		modal.addButton('Zapri', function() { 
		  modal.destroy();
		  $$('table[class="lightface"]').destroy();
			},true);
	}
		
	modal.open();
	
	return modal;
}

function precisci_znake(string){
	return string.toLowerCase().replace(/ž/gi, 'z').replace(/š/gi, 's').replace(/č/gi, 'c').replace(/ć/gi, 'c').replace('\'', '').split(' ').join('_');
}

/* konec FUNKCIJE ZA POMOČ PRI UREJANJU ITEMOV */
/*----------------------------------------------------------------------*/


/* KARENE FUNKCIJE */
/*----------------------------------------------------------------------*/
function mktime() {  
	var no, ma = 0, mb = 0, i = 0, d = new Date(), argv = arguments, argc = argv.length;  
  
	if (argc > 0){  
		d.setHours(0,0,0); d.setDate(1); d.setMonth(1); d.setYear(1972);  
	}  
   
	var dateManip = {  
		0: function(tt){ return d.setHours(tt); },  
		1: function(tt){ return d.setMinutes(tt); },  
		2: function(tt){ var set = d.setSeconds(tt); mb = d.getDate() - 1; return set; },  
		3: function(tt){ var set = d.setMonth(parseInt(tt)-1); ma = d.getFullYear() - 1972; return set; },  
		4: function(tt){ return d.setDate(tt+mb); },  
		5: function(tt){ return d.setYear(tt+ma); }  
	};  
	  
	for( i = 0; i < argc; i++ ){  
		no = parseInt(argv[i]*1);  
		if (isNaN(no)) {  
			return false;  
		} else {  
			// arg is number, let's manipulate date object  
			if(!dateManip[i](no)){  
				// failed  
				return false;  
			}  
		}  
	}  
  
	return Math.floor(d.getTime()/1000);  
}  

function zamenjajVejco(value){
	var tt = value;
	return tt.replace(/,/g, '.');
}
/* konec KARENE FUNKCIJE */
/*----------------------------------------------------------------------*/